@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;600;700&display=swap');

:root{
    --green:#00e5fe;
    --secundario: #15b8ca;
    --terciario: #00fefe;
    --black:#121f41;
    --light-color:#788E9C;
    --light-color-white:#353e44;
    --white: #fff;

    --borde: .1rem solid rgba(0, 0, 0, .1);
    --box-shadow:0 .5rem 1.5rem rgba(0,0,0,.1);

    --borde-claro: .1rem solid rgba(255, 255, 255, .1);
    --box-shadow-claro:0 .5rem .5rem rgba(255,255,255,.1);
}


*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
    text-decoration: none;
    text-transform: capitalize;
    transition: all .2s linear;
}


html{
    font-family: 'Roboto', sans-serif;
    font-size: 62.5%;
    overflow-x: hidden;
    scroll-behavior: smooth;
}

main,
.main {
    padding:2rem 8%;
    overflow: hidden!important;
}


.loading-pag {
    text-align: center;
    background: rgb(14, 12, 12);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
}

.loader {
    position: relative;
}

.loader span {
position: absolute;
color: #fff;
transform: translate(-50%, -50%);
font-size: 7rem;
letter-spacing: 5px;
white-space: pre;
}

.loader span:nth-child(1) {
color: transparent;
-webkit-text-stroke: 0.3px var(--green);
}

.loader span:nth-child(2) {
color: var(--green);
-webkit-text-stroke: 1px var(--green);
animation: PatelRohit 3s ease-in-out infinite;
}

@keyframes PatelRohit {
0%, 100% {
    clip-path: polygon(0% 45%, 15% 44%, 32% 50%, 
    74% 60%, 70% 61%, 84% 59%, 100% 52%, 100% 100%, 0% 100%);
}

50% {
    clip-path: polygon(0% 60%, 16% 65%, 34% 66%, 
    51% 62%, 67% 50%, 84% 45%, 100% 46%, 100% 100%, 0% 100%);
}
}

.heading{
    text-align: center;
    padding-bottom: 3rem;
    margin-top: 8rem;
    font-size: 4.5rem;
    color: var(--black);
}

.service-page .heading{
    margin-top: 7rem;
}

/**botones**/

.custom-btn {
    text-align: center;
    justify-items: center;
    max-width: 18rem;
    height: 4rem;
    color: #fff;
    border-radius: 5px;
    border: 2px solid rgba(0,0,0,.0);
    padding:.8rem 2rem .2rem 2rem;
    cursor: pointer;
    transition: all 0.3s ease;
    position: relative;
    display: inline-block;
    box-shadow:inset 2px 2px 2px 0px rgba(255,255,255,.5),
    7px 7px 20px 0px rgba(0,0,0,.1),
    4px 4px 5px 0px rgba(0,0,0,.1);
    outline: none;
}

body.dark .custom-btn {
    box-shadow: none;
}

.btn {
    background: var(--green);
    color: var(--black);
    z-index: 1;
    font-size: 1.7rem;
    font-weight: 700;
}
.btn:after {
    position: absolute;
    content: "";
    width: 0;
    height: 100%;
    top: 0;
    right: 0;
    z-index: -1;
    background-color: var(--terciario);
    border-radius: 5px;
    box-shadow:inset 2px 2px 2px 0px rgba(255,255,255,.5),
    7px 7px 20px 0px rgba(0,0,0,.1),
    4px 4px 5px 0px rgba(0,0,0,.1);
    transition: all 0.6s ease;
}

body.dark .btn::after {
    box-shadow: none;
}

.btn:hover:after {
    left: 0;
    width: 100%;
}
.btn:active {
    top: 2px;
}

.btn-codigo {
    max-width: 18rem;
    height: 4rem;
    border-radius: .5rem;
    border: 2px solid var(--black);
    color: var(--black);
    font-size: 1.7rem;
    font-weight: 700;
    cursor: pointer;
    background: transparent;
}

.btn-codigo:hover{
    background: var(--black);
    color: var(--white);
}

.cv{
    text-align: center;
    padding:.8rem 2rem .2rem 2rem;
    border: 2px solid var(--white);
    color: var(--white);
}


/********************** Particles **********************/

.particles-css {
	height: 100vh; 
	width: 100%;
    position: fixed;
    z-index: -1;
}

.particles-2-css{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1!important;
}



/********************** Media Q **********************/

@media (max-width: 990px) {
    html{
        font-size: 55%;
    }
    main {
        padding:2rem 4%;
    }
}

@media (max-width: 570px) {
    html{
        font-size: 50%;
    }

    main {
        padding:2rem 5%;
    }

    .loader span {
        font-size: 3rem;
    }
}
/*--------------------------------------------------------------
# Sections General
--------------------------------------------------------------*/
.section {
    padding: 60px 0;
    overflow: hidden;
  }
  
  .section-title {
    text-align: center;
    padding-bottom: 30px;
  }
  
  .background-alt {
    /*background:#f2f2f5*/
    /* background: rgba(45, 55, 78); */
  }
  
  .section-title h2 {
    font-size: 32px;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 20px;
    padding-bottom: 20px;
    position: relative;
    color: rgba(255, 255, 255, 0.8);
  }
  
  .section-title h2::before {
    content: "";
    position: absolute;
    display: block;
    width: 120px;
    height: 1px;
    background: #ddd;
    bottom: 1px;
    left: calc(50% - 60px);
  }
  
  .section-title h2::after {
    content: "";
    position: absolute;
    display: block;
    width: 40px;
    height: 3px;
    background: #0563bb;
    bottom: 0;
    left: calc(50% - 20px);
  }
  
  .section-title p {
    margin-bottom: 0;
    color: #98a6ad;
  }
  
  .experienceos .vertical-timeline-element-content {
    background: none !important;
    padding: none;
  }
  .experienceos .row .columns ul li {

    font-size: 1.5rem;
    line-height: 1.5;
    list-style: disc;
    margin: 1.7rem;

  }
  .experienceos .timeline-date {
    font-size: 20px !important;
  }
  .experienceos .vertical-timeline{
    --line-color: #a3a4ad;

}
  .education .vertical-timeline-element-content {
    background: none !important;
    padding: none;
  }

  .education .timeline-date {
    font-size: 20px !important;
  }
  .education .vertical-timeline{
    --line-color: #a3a4ad;

}
  